<template>
  <card :title="$t('sermons.info')" :link="{to:{name: 'sermons.show', params: {id: this.$route.params.id}}, text: $t('View')}">
    <form @submit.prevent="update" @keydown="form.onKeydown($event)">
      <alert-success :form="form" :message="$t('sermons.updated')" />

      <sermon-form :form="form"></sermon-form>

      <!-- Submit Button -->
      <div class="form-group row">
        <div class="col-md-9 ml-md-auto">
          <v-button :loading="form.busy" type="success">{{ $t('update') }}</v-button>
        </div>
      </div>
    </form>
  </card>
</template>

<script>
import Form from "vform"
import SermonForm from "./form"
import _ from 'lodash'
import { mapGetters } from "vuex"
// import { objectToFormData } from 'object-to-formdata'

export default {
  scrollToTop: false,
  components: {
    "sermon-form": SermonForm
  },
  data: () => ({
    form: new Form({
      videoUrl: "",
      thumbnailUrl: "",
      thumbnail: null,
      title: "",
      date: "",
      description: "",
      verses: "",
      topics: [],
      speaker_id: "",
      speaker: null,
      series_id: "",
      series: null
    })
  }),
  async created() {
    let vm = this;
    let sermon_id = this.$route.params.id;
  
    if (!_.has(this.sermons, sermon_id)) {
      await this.$store.dispatch('admin/fetchSermon', {id: sermon_id});
    }

    let sermon = _.get(vm.sermons, sermon_id);
    console.log('create', sermon);

    vm.refresh(sermon);
  },
  computed: {
    ...mapGetters({
      sermons: "admin/sermons",
    })
  },
  methods: {
    refresh(sermon) {
      this.form.keys().forEach(key => {
        this.form[key] = sermon[key];
      });
    },
    async update() {
      let vm = this;

      this.$store.dispatch("admin/updateSermons", {form: this.form, params: {id: this.$route.params.id}}).then(() => {
        // Update the form
        let sermon = vm.sermons[vm.$route.params.id];
        vm.refresh(sermon);
      });
    }
  }
};
</script>
